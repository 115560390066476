import Image from 'next/image';
import infoIcon from '/public/vectors/info-icon.svg';

export function Membership() {
	return (
		<div className="text-shadow-md w-full rounded-lg bg-yellow-secondary p-4 text-left text-sm">
			<div className="flex flex-row items-start gap-x-3 align-top">
				<Image
					src={infoIcon}
					alt="Info icon"
					aria-hidden="true"
					className="size-6 shrink-0"
				/>
				<div className="pt-0.5">
					<strong>Membership</strong>: Members pay{' '}
					<span className="rounded-sm bg-yellow-tertiary/20 px-0.5">
						£40/month
					</span>{' '}
					and have unlimited access to the Sunday and Thursday
					classes.
				</div>
			</div>
		</div>
	);
}
