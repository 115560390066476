import { SITE } from '@/src/config';
import Image from 'next/image';
import Link from 'next/link';
import infoIcon from '/public/vectors/info-icon.svg';

export function FindingUs() {
	return (
		<div className="text-shadow-md w-full rounded-lg bg-yellow-secondary p-4 text-left text-sm">
			<div className="flex flex-row items-start gap-x-3 align-top">
				<Image
					src={infoIcon}
					alt="Info icon"
					aria-hidden="true"
					className="size-6 shrink-0"
				/>
				<div className="text-pretty pt-0.5">
					<strong>Finding us</strong>: Classes are held at the{' '}
					<span className="rounded-sm bg-yellow-tertiary/20 px-0.5">
						Murrayside Community Centre, Nacton Road, Ipswich, IP3
						9JL
					</span>
					. The building is accessible via the{' '}
					<span className="rounded-sm bg-yellow-tertiary/20 px-0.5">
						side entrance
					</span>
					.{' '}
					<Link
						className="underline decoration-yellow-tertiary decoration-dotted underline-offset-4 hover:bg-charcoal hover:text-white hover:no-underline"
						href={SITE.locationMapSideEntranceLink}
						target="_blank"
					>
						Here is a street view map of the car park driveway
						leading to the side entrance.
					</Link>
				</div>
			</div>
		</div>
	);
}
